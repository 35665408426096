import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import translationEn from "./locales/en/translation.json";
import translationRu from "./locales/ru/translation.json";

/**
 * Return the language based on browser language.
 * Available languages for now is: EN, RU
 *
 * @return {string}
 */
const getBrowserLanguage = () => {
  const defaultLanguage = 'en';
  const availableLanguages = ['ru', 'en'];
  const regex = /^\w\w\b/;

  const browserLanguage = navigator.language.match(regex);
  const language = browserLanguage ? browserLanguage[0].toLowerCase() : '';

  return availableLanguages.includes(language) ? language : defaultLanguage;
};

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: false,
    lng: getBrowserLanguage(),
    fallbackLng: "en",

    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },

    resources: {
      en: {
        translations: translationEn,
      },
      ru: {
        translations: translationRu,
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n;